/* .chart {
    height: 50rem;
    width: 50rem;
  } */

/* .box {
    border: 0.7px solid #c0b9b9;
    padding: 5px;
    padding-right: 5px;
    padding-bottom: 5rem;
  }
  .search_component{
    border: 0.7px solid #615e5e !important;
  } */

*{font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;}

.heading{
  font-size: 16px;
  font-weight: 600;
}

.btnColorStylesNew{
  background-color: #FFCF23 !important;
}

.btnColorStylesSecondaryNew{
  background-color: #e9e9ef !important;  
}

.heading_New_data{
  font-size: 16px;
  font-weight: 600;
}

.sub_heading{
  font-size: 12px;
  font-weight: 500;
}

.sub_heading_title_card{
  font-size: 14px;
  font-weight: 700;
}

.sub_heading_new{
  font-size: 16px;
  font-weight: 500;
}

.body_text{
  font-size: 16px;
  font-weight: normal;
}


.body_text2{
  font-size: 14px;
  font-weight: normal;
}

.tool_tip{
  font-size: 12px;
}




.snipper_wrapper {
  background-color: rgba(0, 0, 0, 0.452);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

  .g-recaptcha {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}

/* Data Not Available & Fetching Data... Stylings */

.data_not_available {
  color: red;
  font-size: 13px;
  text-align: center;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fetch_data {
  color: green;
  font-size: 13px;
  text-align: center;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---------------------------------------------------- */

Table thead{
  position: sticky !important;
  top: 0px !important;
  margin: 0 0 0 0 !important;
}

/* Card Header */

.cardHeader {
  /* background-color: rgba(11, 16, 120, 0.05) !important; */
  /* background-color: #F3F3F8 !important; */
  background-color: #fafafa !important;
}
.lightBlueColor{
  background-color: #f6f9fc !important;
}
.cardHeaderForDBRDFV{
  background-color: #f4f4f4 !important;
}
.cardHeaderForDBRDFVNew{
  background-color: #f6f9fc !important;
}
.last-tr {
  border: "none";
}
.back-btn{
  border: none;
  margin-left: 8px;
  font-weight: 400;
}

.vl {
  border-left: 1.5px solid rgb(211, 211, 211);
  height: 45px;
  margin-top: -10px;
}


.MuiTypography-overline {
  display: none;
}
.form-control.is-invalid{
  background-image: none !important;
}
.react-tooltip {
  z-index: 9999; /* Adjust as needed */
}

.auth-pass-inputgroup .form-control:required:invalid + .input-group-append .input-group-text {
  display: none !important;
}

@media (max-width: 992px) {
  .mbb {
    margin-bottom: 35px;
  }
}


/*card header height responsive for ride data widget in ride details page*/
@media (max-width: 335px) {
  .cardHeaderHeightInRideDetails {
    height: 270px;
  }
}

@media (min-width: 336px) and (max-width: 393px) {
  .cardHeaderHeightInRideDetails {
    height: 220px;
  }
}


@media (min-width: 994px) {
  .cardHeaderHeightInRideDetails {
    height: 151px;
  }
}





.otp-input-container {
  position: relative;
}

.otp-verification-icon {
  position: absolute;
  right: 10px; /* Adjust the right position as needed */
  top: 50%;
  transform: translateY(-50%);
  color: green; /* Adjust the color as needed */
}

.boxShadowCard {
  box-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
  /* box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.56); */
}

.custom-xaxis-text .apexcharts-xaxis text {
  fill: #0095FF !important; /* Your desired color */
}

.custom-yaxis-text .apexcharts-yaxis text {
  fill: #0095FF !important; /* Your desired color */
}

div.apexcharts-legend-series .apexcharts-legend-text {
  color: var(--bs-gray-500) !important;
  font-family: var(--bs-font-sans-serif) !important;
  font-size: 10px !important;
}


/* Placeholder fontsize change */

.specific-input::placeholder {
  font-size: 12px; 
}  
