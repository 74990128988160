.form-text{
    color: red;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.line-divider {
  width: 100%; /* Set the width of the line */
  border-top: 1px solid #c8c5c5; /* Set the color and thickness of the line */
  margin: 15px 0; /* Adjust the margin as needed */
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) { 
  .SearchUiAlignment{
    margin-left:20px !important;
  }
}