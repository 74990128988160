.menu-active{
    background-color: #2C60D3 !important;
    color: #fff !important;
    white-space: nowrap !important;
    margin-right: 5px !important;
    padding: 5px 10px 6px 10px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}
.menu-inactive{
    background-color: #D9D9D9 !important;
    color: #000 !important;
    /* border: 1px solid #000 !important; */
    white-space: nowrap !important;
    margin-right: 5px !important;
    padding: 5px 10px 6px 10px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
}


.adminmenu-height{
    height: 80px !important;
}
.adminmenu-mt{
    margin-top: 10px !important;
}

@media (min-width: 991.1px) and (max-width: 1199px)  {
    .adminmenu-height{
        height: 60px !important;
    }
    .adminmenu-mt {
        margin-top: 50px !important;
    }
}

@media (max-width: 991px){
    .adminmenu-height{
        height: 20px !important;
        margin-top: -20px !important;
    }
    .adminmenu-mt{
        margin-top: 90px !important;
    }
}

@media (max-width: 649px){
    .adminmenu-mt{
        margin-top: 115px !important;
    }
}

@media (max-width: 401px){
    .adminmenu-mt{
        margin-top: 160px !important;
    }
}

@media (max-width: 360px){
    .adminmenu-mt{
        margin-top: 220px !important;
    }
}