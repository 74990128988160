.google-map-marker {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #FFF; /* Set the background color of the marker */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Customize the box shadow */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  }
  
  /* .google-map-marker:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 10px;
    background: #000;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%) skew(-30deg);
    border-radius: 2px;
  } */