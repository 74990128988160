.popup_Button{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #2C60D3 !important;
    text-transform: capitalize !important;
    border-color: #2C60D3;
}

.popup_Button:hover{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #f1f2f4 !important;
    text-transform: capitalize !important;
    border-color: #5156BE;
    background-color: #5156BE !important;
}

.btn-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Modal-body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    height: auto;
    border-radius: 15px;
    box-shadow: 24px;
    text-align: center;
    margin-top: 15px;
   
}
