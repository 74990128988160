  .td-container {
    font-size: 10px;
    vertical-align: middle;
  }
  
  .div-flex {
    display: flex;
  }

  .div-flex-margin {
    display: flex;
    margin-top: 10px;
  }

  .second-div-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 12px;
  }

  .div-flex-device {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: -2px;
  }

  .p-tag-device-name {
    font-size: 11px;    
    margin-bottom: 0px;    
    cursor: default;
    margin-top: 3px;
  }

  .p-tag-vehicle-name {
    font-size: 11px;    
    margin-bottom: 0px;    
    cursor: pointer;
    margin-top: 3px;
  }
  
  .span-tag-name {
    font-size: 11px;
    color: #5156BE;
    margin-bottom: 0px;
    font-weight: 700;
    cursor: default;
    margin-top: 2px;
  }

  .p-tag-username {
    font-size: 11px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 2px;
  }
  
  .statusButton {
    background-color: #D7EC23;
    padding: 3px;
    font-size: 8px;
    font-weight: 600;
  }
  
  .div-spacing {
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 0px;
  }


  .div-flex-reg-act {
    margin-left: -2px;
    margin-top: 10px;
    display: flex;
  }

  .div-flex-error {
    margin-left: 4px;
    margin-top: 10px;
    display: flex;
  }

  .p-tag-text-right {
    font-size: 11px;
    margin-bottom: 0px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 3px;
  }

  .p-tag-text-left {
    font-size: 11px;
    margin-bottom: 0px;
    margin-left: 5px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .div-flex-reg-detail {
    margin-left: -2px;    
    display: flex;
  }