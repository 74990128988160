.custom-mobile-date-picker {
    /* Adjust the size of the date picker container */
    width: 150px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
    font-size: 10px !important;
  }
  
  .custom-mobile-date-picker .picker-container {
    /* Adjust the size of the date picker itself */
    width: 90%; /* Adjust the width as needed */
    height: 1%; /* Adjust the height as needed */
    font-size: 10px !important;
  }
  .custom-mobile-date-picker .picker-label {
    color: #333;
  }
 