
.adminmenu-height{
    height: 60px !important;
}
.adminmenu-mt{
    margin-top: 10px !important;
}

@media (min-width: 991.1px) and (max-width: 1199px)  {
    .adminmenu-height{
        height: 60px !important;
    }
    .adminmenu-mt {
        margin-top: 8px !important;
    }
}

@media (max-width: 991px){
    .adminmenu-height{
        height: 20px !important;
        margin-top: -20px !important;
    }
    .adminmenu-mt{
        margin-top: 50px !important;
    }
}

@media (max-width: 649px){
    .adminmenu-mt{
        margin-top: 115px !important;
    }
}

@media (max-width: 401px){
    .adminmenu-mt{
        margin-top: 160px !important;
    }
}

@media (max-width: 360px){
    .adminmenu-mt{
        margin-top: 220px !important;
    }
}