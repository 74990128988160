.Button1{
    border-radius: 5px;
    height: 35px;
    width: 90px;
    color: #000 !important;
    background-color: #fff !important;
    font-size: 15px;
    font-weight: 700;
    margin-right: 15px;
}
.Button1:hover{
    color: #fff !important;
    background-color: #2C60D3 !important;
}

.Button2{
    border-radius: 5px;
    height: 35px;
    width: 90px;
    color: #000 !important;
    background-color: #fff !important;
    font-size: 15px;
    font-weight: 700;
}
.Button2:hover{
    color: #fff !important;
    background-color: #2C60D3 !important;
}

.custom-modall {
      border-radius: 10px !important; /* Set the border radius to 10px */
 
  }
