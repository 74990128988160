form.example::after {
    content: "";
    clear: both;
    display: table;
  }



  #table-wrapper {
    position:relative;
  }
  #table-scroll {
    height:250px;
    overflow:auto;  
    margin-top:20px;
  }
  #table-wrapper table {
    width:100%;
      
  }
  #table-wrapper table * {
    background:white;
    color:black;
    border: "0.2px",

  }
  #table-wrapper table thead th .text {
    position:absolute;   
    top:0px;
    z-index:2;
    height:80px !important;
    /* width:35%; */
    font-weight:600;
    font-size:12px !important;
    text-align:center;
    vertical-align:"middle";
    border:1px solid red;
  }

 
  
  

  

 