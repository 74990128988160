.custom-modal-overlay {

  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  bottom: 0;

  background-color: rgba(

    0,

    0,

    0,

    0.5

  ); /* Fallback color for unsupported browsers */

  backdrop-filter: blur(0px); /* Apply your desired backdrop-filter effect */

  display: flex;

  justify-content: center;

  align-items: center;

}

 

.pagination {

  display: inline-block;

}

 

.pagination a {

  color: black;

  float: left;

  padding: 8px 16px;

  text-decoration: none;

  background-color: #74788D1A;

  border-radius: 4px;

}

 

/* .pagination a.active {

  background-color: #4CAF50;

  color: white;

} */

 

/* .pagination a:hover:not(.active) {background-color: #ddd;} */