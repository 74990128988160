.border-green{
    border: 1px solid  #05c54f;
  }
  .border-yellow{
    border: #DEA602 solid 1px;
  }
  .border-red{
    border: #E21212 solid 1px;
    /* background-image: url('../../assets/images/TripIcons/alert_end.png'); */
  }

  #mapContainer {
    width: 100%; /* Adjust to your desired width */
    height: 400px; /* Adjust to your desired height */
    /* position: relative; */
    z-index: 1; /* Ensure the map stays on top of other elements */
    overflow: auto;
    /* Add any additional styling as needed */
  }
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff; /* Set the background color as needed */
    z-index: 1;
  }


  table#riskstyles tr td:first-child{display:flex;}

/* Hover effect for icon in Driving Score */

  .hover-animation {
    transition: transform 0.3s; 
  }
  
  .hover-animation:hover {
    transform: rotate(360deg);
  }