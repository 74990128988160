.work_personal_filter {
  width: 45%;
  color: white !important;
  border-radius: 8px !important;
  margin-top: 1px !important;
  float: right;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  background: #979797 !important;
  border: none !important;
  text-align: left !important;
}
.card_style {
  border-top:  1px solid rgb(80, 76, 76) !important;
  border-left:  1px solid rgb(80, 76, 76) !important;
  border-right:  1px solid rgb(80, 76, 76) !important;
  border-bottom: 1px solid rgb(80, 76, 76) !important;
  border-radius: 20px !important;
  margin-bottom: 10px !important;
  /* padding: 20px; */
}
.rideNo {
  margin-left: 2px;
  font-weight: 600;
  color: #001a46;
}
.bluetooth {
  /* width: 18px;
  height: 16px; */
  flex-shrink: 0;
  margin-left: 9px;
}
.car {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 1px;
}

.safetyField{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 1px;
}
.sandClockIcon {
  width: 13px;
  height: 15px;
  flex-shrink: 0;
  margin-right: 4px;
}
.gpsRouteIcon {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  margin-right: 4px;
  margin-top: 3px;
}
.startIcon {
  height: 17px;
  width: 17px;
  flex-shrink: 0;
}
.startOverlayIcon {
  height: 9px;
  width: 9px;
  flex-shrink: 0;
  margin-bottom: 25px;
}
.destinationIcon {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
}
.location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin-bottom:"0px"!important;
  cursor: pointer;
  /* margin-left: 8px; */
}
.circularProgressBarDiv{
    height: 54px;
    width: 54px;
    float: right !important;
    /* margin-top: 1.6px */
}

.yellowCalendarIcon{
  width: 14px;
  height:14px;
  /* margin-left: 5px; */
}

.yellowsteeringIcon{
  width: 14px;
  height:14px;
  /* margin-left: 5px; */
  margin-bottom: 3px;
}

.progress-bar-rideList .CircularProgressbar-text {
  font-weight: 700 !important; /* Set to bold or adjust to any other value */
}